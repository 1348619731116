import React, { useEffect, useState } from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import {
  Input,
  Button,
  CenteredContentWrapper,
  TextBody
} from "@xolvio/xolvio-ui";
import Post from "./blog/Post/Post";
import Posts, { PostTiles } from "./blog/Posts/Posts";
import styled from "styled-components";
import { spacing } from "@xolvio/xolvio-ui";

export default ({ renderDevNotes }) => {
  const [data, setData] = useState(null);
  const [apiKey, setValue] = useState(
    typeof window !== "undefined" &&
      window.localStorage.getItem("xolvioContentApiKey")
  );
  const [post, selectPost] = useState(null);

  const getAllPosts = () => {
    window &&
      fetch(
        `https://xolvio.ghost.io/ghost/api/v3/content/posts/?key=${apiKey}&include=tags,authors,excerpt&limit=all`
      )
        .then(response => response.json())
        .then(resultData => {
          setData(resultData);
        });
  };

  useEffect(() => {
    if (apiKey) {
      getAllPosts();
    }
  }, []);
  if (apiKey && data) {
    return (
      <LayoutDefault>
        <Wrapper>
          <PostTiles
            posts={data.posts.map(node => ({ node }))}
            renderDevNotes={renderDevNotes}
            isPreview={true}
          />
        </Wrapper>
      </LayoutDefault>
    );
  }

  return post ? (
    <Post data={{ ghostPost: post }} />
  ) : (
    <LayoutDefault>
      <CenteredContentWrapper>
        ApiKey:{" "}
        <Input value={apiKey} onChange={e => setValue(e.target.value)} />
        <Button
          onClick={() => {
            window &&
              window.localStorage.setItem("xolvioContentApiKey", apiKey);
            getAllPosts();
          }}
        >
          Get articles
        </Button>
      </CenteredContentWrapper>
    </LayoutDefault>
  );
};
const Wrapper = styled(CenteredContentWrapper)`
  margin: 120px ${spacing.mobile.padding.default}px 80px;
`;
