import React, { useEffect, useState } from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import {
  Input,
  Button,
  CenteredContentWrapper,
  TextBody
} from "@xolvio/xolvio-ui";
import Post from "./blog/Post/Post";
import { PostTiles } from "./blog/Posts/Posts";
import { parseQuery } from "../pages/contact-us";
import { useLocation } from "@reach/router";

export default () => {
  const apiKey =
    typeof window !== "undefined" &&
    window.localStorage.getItem("xolvioContentApiKey");
  const [post, setData] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (window && apiKey) {
      const slug = location.pathname
        .replace("/preview/articles/", "")
        .replace("/preview/dev-notes/", "")
        .replace("/preview/", "")
        .replace("/", "");
      fetch(
        `https://xolvio.ghost.io/ghost/api/v3/content/posts/slug/${slug}/?key=${apiKey}&include=tags,authors,excerpt`
      )
        .then(response => response.json())
        .then(resultData => {
          setData(resultData.posts[0]);
        });
    }
  }, []);
  return post ? (
    <Post
      data={{
        ghostPost: post,
        site: { siteMetadata: { siteUrl: "https://xolv.io/" } }
      }}
    />
  ) : (
    <LayoutDefault>loading live preview</LayoutDefault>
  );
};
