import React from "react";
import { Router } from "@reach/router";
import BlogPreviewLive from "../components/BlogPreviewLive";
import PostPreviewLive from "../components/PostPreviewLive";

export default () => {
  return (
    <Router basepath="/preview">
      <BlogPreviewLive path="/dev-notes" renderDevNotes />
      <BlogPreviewLive path="/articles" exact />
      <PostPreviewLive path="/:slug" />
    </Router>
  );
};
